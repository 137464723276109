.App {
  text-align: center;
}

.Jane {
  max-width: 100vw;
}

.Header {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.Headline {
  padding: 4vw 2vw 0vw 2vw;
  text-align: left;
  font-weight: 500;
  font-size: 8vw;
}

.Subheading {
  padding: 3vw 2vw 5vw 2vw;
  text-align: left;
  font-size: 4vw;
  color: grey;
}
.Paragraph {
  padding: 0vw 2vw 5vw 2vw;
  text-align: left;
  font-size: 4vw;
}

.App-link {
  color: #61dafb;
}
